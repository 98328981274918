/* /* .back {
    background-color: azure !important;
}

.div_login{
    width: 70%;
    max-width: 1320px;
    background-color: white;
    padding: 1rem;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
}

.div_register{
    width: 70%;
    max-width: 1320px;
    background-color: white;
    padding: 1rem;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2);
}

label{
    color: black;
} */

/* Please ❤ this if you like it! */


@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

.back {
  overflow-x: hidden;
  background-image: url('../images/login.svg');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 2100px !important;
  /* background-color: black ; */
}
.agreement-p {
  margin-top: 15px!important;
  color: white;
}
.agreement {

  display: flex;
}
.accept {
  z-index: 20 !important;
  border: 10px solid white;
  margin: 10rem 0;
}
.log-sign {
  /* margin-right: 33%; */
}

.back {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 15px;
  /* line-height: 1.7; */
  /* overflow-x: hidden; */
}

a {
  color: #f5a100;
  cursor: pointer;
  transition: all 200ms linear;
}

a:hover {
  color: #f5a100;
  text-decoration: none;
}

.link {
  color: #f5a100;
}

.link:hover {
  color: #f5a100;
}

p {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
}
.btn-auth{
    color: black  !important;
}
h4 {
  color: #f5a100;
  font-weight: 600;
}

h6 span {
  color: #f5a100;
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 700;
}

.section {
  position: relative;
  width: 100%;
  display: block;
}

.full-height {
  /* min-height: 100vh; */
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  z-index: 3;
  left: -9999px;
}

.checkbox:checked+label,
.checkbox:not(:checked)+label {
  position: relative;
  display: block;
  text-align: center;
  width: 60px;
  height: 16px;
  border-radius: 8px;
  padding: 0;
  margin: 10px auto;
  cursor: pointer;
  background-color: #f5a100;
}

.checkbox:checked+label:before,
.checkbox:not(:checked)+label:before {
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #f5a100;
  background-color: #000000;
  font-family: 'unicons';
  content: '\eb4f';
  z-index: 20;
  top: -10px;
  left: -10px;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  transition: all 0.5s ease;
}

.checkbox:checked+label:before {
  transform: translateX(44px) rotate(-270deg);
}


.card-3d-wrap {
  position: relative;
  width: 300px;
  height: 450px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 800px;
  margin-top: 20px;
}

.card-3d-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 600ms ease-out;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  /* From https://css.glass */
  background: rgba(41, 41, 41, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(41, 41, 41, 0.17);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 300%;
  position: absolute;
  border-radius: 6px;
  left: 0;
  top: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

.checkbox:checked~.card-3d-wrap .card-3d-wrapper {
  transform: rotateY(180deg);
}

.center-wrap {
  position: absolute;
  width: 100%;
  padding: 35px;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 35px) perspective(100px);
  z-index: 20;
  display: block;
}


.form-group {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.form-style {
  padding: 13px 20px;
  padding-left: 55px;
  height: 38px;
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  outline: none;
  color: #c4c3ca;
  /* From https://css.glass */
  background: rgba(41, 41, 41, 0.17);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(41, 41, 41, 1);
  border: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, .2);
}

.form-style:focus,
.form-style:active {
  border: none;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, .2);
}

.input-icon {
  position: absolute;
  top: -7px;
  left: 10px;
  height: 38px;
  font-size: 24px;
  line-height: 48px;
  text-align: left;
  color: #f5a100;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:-ms-input-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input::-moz-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:-moz-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input::-webkit-input-placeholder {
  color: #c4c3ca;
  opacity: 0.7;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:focus:-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:focus:-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.btn {
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  padding: 0 30px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  line-height: 20px !important;
  background-color: #f5a100;
  color: black !important;
  box-shadow: 0 8px 24px 0 rgba(255, 235, 167, .2);
}

.btn:active,
.btn:focus {
  background-color: #102770;
  color: #f5a100;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, .2);
}

.btn:hover {
  background-color: #f5a100;
  color: #f5a100;
  box-shadow: 0 8px 24px 0 rgba(16, 39, 112, .2);
}




.logo {
  position: absolute;
  top: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}

.logo img {
  height: 26px;
  width: auto;
  display: block;
}
@media (min-width: 320px) {
  .log-sign {
    margin: auto !important;
    position: relative;
    margin-top: -20% !important;
  }
}
@media (width: 768px) {
  .back {
    background-position: 100% ;
    background-size: 150%;
  }
  .log-sign {
    margin-top: -5% !important;
    margin-left: -40% !important;
  }
 
}
@media (min-width:  1024px) {
  .back {
    /* overflow: hidden; */
    background-image: url('../images/login.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .log-sign {
    margin-top: 0% !important;
    /* margin-left: -30% !important; */
  }
}
.back-rejester{
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.6px);
    -webkit-backdrop-filter: blur(5.6px);
    border: 1px solid rgba(41, 41, 41, 0.17);
    margin-left: 18% !important;
}
.mx-auto{
  margin-left: 22% !important;
}
.links-reg-log{
  margin-right: 33%;
}
.links-reg{
  margin-right: 62%;
  margin-top: 17px !important;
}
@media(max-width : 560px){
  .links-reg-log{
    margin-right: 0% !important;
  }
  .mx-auto{
    margin-left: 11% !important;
  }
  .back-rejester{
    width: 68% !important;
  }
  .links-reg{
    margin-right: 30%;
    margin-top: 17px;
  }  
}
