*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.div_form_ticket{
    background-color: #1D1D1D;
    width: 90%;
    margin: 0 auto;
    max-width: 1320px;
    position: relative;
    top: 40px;
}
.form-ticket
{
  width: 90%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 1320px;
}
Label{
color: #F5A100;
}
input[name="title"]{
    background-color: #242424;
    color: white;
}
input[name="title"]::placeholder{
    color: white;
}
input[name="title"]:focus{
    background-color: #242424;
    color: white;
}
select[name="priority"]{
    background-color: #242424;
    color: white;
}
textarea[name="text"] {
    background-color: #242424;
}
textarea[name="text"]:focus{
    background-color: #242424;
    color: white;
}
.history{
    background-color: #1D1D1D;
    margin: 0 auto;
    width: 90%;
    max-width: 1320px;
    position: relative;
    top: 60px;
}

.table_history{
    width: 90%;
    margin: 0 auto;
    position: relative;
    top: 10px;
    padding: 40px;
}
@media only screen and (max-width: 600px) {
.ticket-btn{
    margin-right: 71% !important;
}
}