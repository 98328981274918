*{
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}
.dashboard_div{
    width: 90%;
    max-width: 1320px;
    margin: 0 auto !important;
    background-color: #1D1D1D;
    position: relative;
    top: 30px;
    display: flex;
    justify-content: space-evenly;
}
.info_user{
    background-color: #242424;
    width: 28%;
    position: relative;
    height: 238px;
    top: 24px;
    border-radius: 10px;
}
.info_user > h6 {
    color: #F5A100;
    text-align: center;
    position: relative;
    top: 15px;
}
.info_user > ul > li {
    color: #F5A100;
    list-style: none;
    position: relative;
    right: 20px;
    top:30px;
    padding: 12px;
}
.info_user > p {
    color: #F5A100;
    font-size: 13px;
    text-align: center;
    padding-top: 15px;
}
.banner-image{
    position: relative;
    top: 60px;
}
.div_table {
    position: relative;
    top: 64px;
    background-color: #1D1D1D;
    max-width: 1320px;
    width: 90%;
    margin: 0 auto;
}
.div_table Table {
    width: 90%;
    margin: 0 auto;
    background-color: #242424;
}
.tbody, td, tfoot, th, thead, tr{
    background-color: #242424 !important;
    color: #F5A100 !important;
}
.btn_more{
    color: white;
    width: 100px;
    height: 42px;
    background-color: #F5A100;
    line-height: 2;
    margin-left: -14px;
    text-align: center;
}
.btn_more:hover{
    background-color: #F5A100;
    color: white;
}
@media (max-width: 991px) {
    .dashboard_div{
        flex-direction: column;
    }
    .info_user{
        width: 100%;
        top: -7px;
        height: 188px;
    }
    .info_user > ul > li{
        padding: 4px;
    }
    .banner-image{
        top: 0;
        text-align: center;
    }
}