*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.menu_div{
    background-color: #242424;
    width: 25%;
    max-width: 1320px;
    border-radius: 10px;
    margin-top: 23px;
    margin-bottom: 23px;
}
.menu_div>ul > li{
    border-bottom: 1px solid black;
    list-style: none;
    padding: 8px;
}
.menu_div>ul > li > a{
    text-decoration: none;
    color: #F5A100;
    padding-right: 20px;
    display: block;
    font-size: 13px;
}

@media (max-width: 998px) {
    .menu_div{
        width: 100%;
    }
}