*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.div_survey{
    width: 90%;
    background-color: #1D1D1D;
    margin: 0 auto;
    max-width: 1320px;
    position: relative;
    top: 50px;
    display: flex;
}
.banner-survey{
    width: 30%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}
.form-survey{
    width: 60%;
    position: relative;
    right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
}
.name-pro{
    display: flex;
}
input[name=name]{
    background-color: #242424;
}
input[name=name]:focus{
    background-color: #242424;
    color:white;
}
input[name=name]::placeholder{
    color:white;
}
@media (max-width: 982px) {
    .div_survey{
        flex-direction: column;
    }
    .banner-survey{
        width: 100%;
        padding-right: 0;
    }
    .form-survey{
        width: 100%;
        right: 0;
    }
    .name-pro{
        flex-direction: column;
    }
}