*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.invitation_div_top{
    width: 90%;
    margin: 0 auto;
    background-color: #1D1D1D;
    position: relative;
    top: 50px;
    max-width: 1320px;
    height: 262px;
}
.invitation_div_top h5 {
    color: #F5A100;
    margin-top: 10px;
}
.invitation_div_top_right{
    width: 100%;
    background-color: #242424;
    position: relative;
    top: 54px;
    right: 20px;
    color:#F5A100;
    height: 177px;
    text-align: justify;
}
.card_code_invite{
    float: left;
    position: relative;
    left: 25px;
    top: 21px;
    color: #F5A100;
    background-color: #242424;
    width: 46%;
    height: 107px;
    text-align: center;
    line-height: 3;
}
.btn_invite_copy{
    float: left;
    position: relative;
    top: 143px;
    right: 41%;
}
.btn_invite_copy button {
    outline: none;
    border: none;
    color: #F5A100;
    background: #242424;
    height: 39px;
    padding: 7px;
}
.btn_invite_copy > button:nth-child(1){
    margin-left: 10px;
}
.btn_invite_copy > button:nth-child(2){
    width: 110px;
}
.btn-show-edu{
    float: left;
    position: relative;
    top: 154px;
    right: 41%;
    width: 46%;
    height: 41px;
    border: none;
    color: #F5A100;
    background-color: #242424;
}
.invitation_div_bottom {
    width: 90%;
    margin: 0 auto;
    background-color: #1D1D1D;
    position: relative;
    top: 80px;
    max-width: 1320px;
    height: 262px;
}
.invitation_div_bottom h5 {
    color: #F5A100;
    margin-top: 10px;
}
.div_table{
    position: relative;
    top:100px;
}
.div_table h5{
    color: #F5A100;
    margin-top: 10px;
}
@media (max-width: 820px) {
    .invitation_div_top{
        flex-direction: column;
        width: 90%;
        height:  398px;
    }
    .invitation_div_top_right {
        top: 1px;
        right: 56px;
        height: 110px;
        width: 178%;
    }
    .card_code_invite{
        width: 89%;
        margin: 0 auto;
    }
    .btn_invite_copy {
        float: left;
        position: relative;
        top: 31px;
        right: -31%;
    }
    .btn-show-edu {
        top: 82px;
        right: 9%;
    }
}

@media (max-width: 767px)  {
    .invitation_div_top{
        flex-direction: column;
        width: 100%;
        height:  514px;
    }
    .invitation_div_top_right{
        top: 1px;
        right: 0;
        height: 247px;
        width: 100%;
        padding: 1rem;
    }
    .card_code_invite{
        width: 89%;
        margin: 0 auto;
    }
    .btn_invite_copy {
        float: left;
        position: relative;
        top: 31px;
        right: -14%;
    }
    .btn-show-edu{
        top: 40px;
        right: -28%;
    }
}