*{
    margin: 0;
    padding: 0;
    /* background-color: #1D1D1D; */
}

.div_cart{
    position: relative;
    top: 20%;
    max-width: 1320px !important;
    width: 90%;
    margin: 0 auto;
}

.email-file{
    display: flex;
}

.name_input{
    margin-right: 10%;
    width: 63%;
    background-color: #242424;
}
.name_input::placeholder{
    color: #F5A100;
}

.name_input:focus{
    background-color: #242424;
}

.course_name{
    
    margin-right: 17%;
    width: 73%;
    background-color: #242424;
    color: #F5A100;
}
.course_name > option{
    color: #F5A100;
}

.course_name:focus{
    background-color: #242424;
    color: white;
}

.qr_code{
    width: 74%;
    height: 215px;
    margin-right: 17%;
    background-color: #242424;
    margin-top: -39px;
}

#episode_id{
    margin-right: 60px;
    width: 63%;
    background-color: #242424;
    color: #F5A100;
}

#price_place{
    width: 64%;
    height: 35px;
    border-radius: 5px;
    background-color:#242424;
    margin-right: 9%;
    color: #F5A100;
    font-size: 15px;
    margin-top: 10px;
}
.email{
    width: 66%;
    margin-right: 7%;
    background-color: #242424;
    color: white;
}
.email::placeholder{
    color: #F5A100;
}
.email:focus{
    background-color: #242424;
    color: white;
}

.img_shot{
    width: 74%;
    margin-right: 17%;
    background-color: #242424;
}
.img_shot::placeholder{
    color: #F5A100;
}

#shot_label{
    color: #F5A100;
    margin-right: 17%;
}

.txid{
    width: 67%;
    margin-right: 6%;
    background-color: #242424;
}

.txid::placeholder{
    color: #F5A100;
}
.txid:focus{
    background-color: #242424;
    color: white;
}

#role_label{
    color: #F5A100;
}
#role{
    margin-right: 18%;
}
.submit-course{
    margin-right: 3%;
}

#trc20{
    width: 63.5%;
    background-color: #242424;
    margin-right: 9%;
    margin-top: 10px;
    color: #F5A100;
}

#bep20{
    width: 63.5%;
    background-color: #242424;
    margin-right: 9%;
    margin-top: 10px;
    color: #F5A100;
}

.name_bep20 , .name_trc{
margin-right: 9%;
color: #F5A100;
}

.step_buy{
    background-color: #242424;
    width: 100%;
    margin: 0 auto;
    color: #F5A100;
    padding: 10px;
}
.step_buy p {
    color: white;
}

@media (max-width : 860px){
    .res-episode{
        flex-direction: column;
    }
    .qr_code{
    width: 100%;
    height: 215px;
    margin-right: 0%;
    background-color: #1D1D1D;
    margin-top: -39px;
    }
    .qr_code div img {
        width: 150px;
        height: 150px;
    }
    #trc20{
        margin-right: 0;
        width: 100%;
    }
    #bep20{
        margin-right: 0;
        width: 100%; 
    }
    #episode_id{
        margin-top: 10px;
    }
    .name_input , .course_name , #episode_id,#price_place, #trc_20 , #bep_20{
        margin-right: 0 !important;
        width: 100% !important;
    }
}